import {NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatAnchor, MatButton} from "@angular/material/button";
import {LoginService} from '../../core/services/login/login.service';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {SessionService} from "../../core/services/session/session.service";
import {AutofocusDirective} from "../../shared/directives/autofocus/autofocus.directive";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, MatProgressSpinner, MatButton, MatIcon, MatAnchor, AutofocusDirective],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  constructor(private _loginService: LoginService,
              private fb: FormBuilder,
              private router: Router,
              private _sessionService: SessionService,
              private _snackBar: MatSnackBar,
              private _activatedRoute: ActivatedRoute
             )
  {
    this.formOtp = this.fb.group({
      otp: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
    });
    this.formMobile = this.fb.group({
      mobile: ['']
    });
  }
  formMobile: FormGroup ;
  formOtp: FormGroup ;
  showLoader = false;
  mobileNo = '';
  nonce = '';
  visible1 = true;
  visible2 = false;
  errorMessage = '';
  sendingOtp: boolean = false;
  validatingOtp: boolean = false;
  loginError: string = '';
  OtpLoading: boolean = false;
  redirectUrl: string | null = null;

  ngOnInit() {
    this.formMobile = this.fb.group({
      mobile: [''],
      deviceId: ['device#2']
    });
    this.formOtp = this.fb.group({
      otp: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
    });

    this._activatedRoute.queryParams.subscribe(params => {
      const redirectParam = params['redirectURL'];
      if (redirectParam) {
        this.redirectUrl = decodeURIComponent(decodeURIComponent(redirectParam));
      }
    });
  }

  getLoginOtp(): void {
    this.OtpLoading = true;
    this._loginService.getOtp(this.formMobile?.value).subscribe({
      next: res => {
        this._snackBar.open("OTP send Successfully!","dismiss",{
          duration:5000,
          verticalPosition:"bottom",
          horizontalPosition:"right"
        });
        this.nonce = res.nonce;
        this.visible2 = true;
        this.visible1 = false;
        this.mobileNo = this.formMobile?.get('mobile')?.value
      },
      error: err => {
        this.errorMessage = err.message;
        this.sendingOtp = false;
        this.OtpLoading = false
        this.showLoader = false;
        this._snackBar.open("Error occurred while sending OTP.", "dismiss", {
          duration: 5000,
          verticalPosition: "bottom",
          horizontalPosition: "right"
        });
      },
      complete: () => {
        this.showLoader = false;
        this.sendingOtp = false;
        this.OtpLoading = false;
      }
    });
  }

  login(): void {
    this.showLoader = true;
    this.validatingOtp = true;
    const otpValue =this.formOtp?.value.otp;
    if (this.formOtp?.invalid) {
      return;
    }
    this._loginService.login({
      mobile: this.mobileNo,
      otp: otpValue,
      nonce: this.nonce
    }).subscribe({
      next: res => {
        this._sessionService.createSession(res.accessToken, res.id,res.refreshToken);
        setTimeout(() => {
          if (this.redirectUrl) {
            this.router.navigateByUrl(this.redirectUrl);
          } else {
            this.router.navigate(['brain/dashboard']);
          }
          this._snackBar.open("Login Successfully!", "dismiss", {
            duration: 5000,
            verticalPosition: "bottom",
            horizontalPosition: "right",
          });
        }, 1000);
      },
      error: err => {
        this.validatingOtp = false;
        this.errorMessage = err.message;
        this._snackBar.open("Error while login, Check OTP", "Close", {
          duration: 5000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
        });
      },
      complete: () => {
        this.validatingOtp = false;
      }
    });
  }

}
