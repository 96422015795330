import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
  standalone: true
})
export class AutofocusDirective {

  @Input() appAutofocus: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appAutofocus'].currentValue) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 0);
    }
  }

}
