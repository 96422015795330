import { Observable } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateFn } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
class LoginService {

  constructor(private _sessionService: SessionService, private router: Router) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._sessionService.getAccessToken()) {
      this.router.navigateByUrl('brain/dashboard');
      return false;
    } else {
      return true;
    }
  }
}

export const LoginRedirectGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(LoginService).canActivate(next, state);
}
