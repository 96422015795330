import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {LoginService} from '../services/login/login.service';
import {SessionService} from '../services/session/session.service';
import {BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private router: Router,
              private _loginService: LoginService,
              private _sessionService: SessionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {
    let authReq = request;
    const url: String = request.url;

    if(url.includes('login')|| url.includes('oauth/token')){
      return next.handle(request);
    }

    const token = this._sessionService.getAccessToken();

    if(token){
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(catchError((error: HttpErrorResponse)=>{
      if(error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(authReq, next);
      } else if (error instanceof HttpErrorResponse && error.status === 403) {
        this.logOutUser();
        return throwError(() => new Error("Forbidden"));
      }
      return throwError(() => error);
    }));
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken: string | null = this._sessionService.getRefreshToken();
      if(refreshToken) {
        const data = new FormData();
        data.set("refresh_token", refreshToken);
        data.set("grant_type", "refresh_token");
        return this._loginService.refreshAccessToken(data).pipe(
          switchMap((tokenResponse)=> {
            this.isRefreshing = false;
            const newAccessToken = tokenResponse.access_token;
            this._sessionService.updateSession(newAccessToken, refreshToken);
            this.refreshTokenSubject.next(newAccessToken);
            return next.handle(this.addToken(req, newAccessToken));
        }),catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              /* Window Location will be the seprate service And replace with route snap shot state*/
              const currentUrlEncoded = encodeURIComponent(window.location.pathname + window.location.search + window.location.hash);
              this.logOutUser(currentUrlEncoded);
            }
            return throwError(() => new Error("Failed to refresh token"));
        }));
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token )=> next.handle(this.addToken(req, token as string)))
    );
  }

  private addToken(request: HttpRequest<any>, token: string) : HttpRequest<any> {
    return request.clone({
      setHeaders:{
        'authorization':`Bearer ${token}`
      }
    });
  }

  private logOutUser(url?: string) {
    localStorage.clear();
    this.isRefreshing = false;
    if(url) {
      this.router.navigate(['/login'], { queryParams: { redirectURL: url } });
    } else {
      this.router.navigate(['/login']);
    }
  }

}
