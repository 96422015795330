import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  httpOptions={
    headers: new HttpHeaders({
      'Content-Type':'application/json',
      'client-id':'100'
    })
  }

  constructor(private _http: HttpClient) { }

  getOtp(requestPayload: any): Observable<any>{
    return this._http.post<any>(`${environment.lfyAuth}/account/otp/send`, requestPayload, this.httpOptions);
  }

  login(requestPayload: any):Observable<any>{
    return this._http.post<any>(`${environment.lfyAuth}/account/otp/validate`, requestPayload, this.httpOptions);
  }

  refreshAccessToken(data: FormData) {
    return this._http.post<any>(`${environment.lfyAuth}/oauth/token`, data, {
      headers:{'authorization':`Basic ${environment.basicAuth}`}
    })
  }

}
