import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class RedirectUrlService {

  constructor(@Inject(DOCUMENT) private _doc: Document, @Inject(PLATFORM_ID) private readonly platformId: any) {}

  getQueryParameterByName(url = this.getWindow()?.location.href) : string | null {
    if (!url) {
      url = this.getWindow()?.location.href ?? '';
    }
    const parsedUrl = this.processUrl(url);
    let fullURL : string;
    if(parsedUrl) {
      if(parsedUrl.hash) {
        fullURL = parsedUrl.pathname+parsedUrl.hash
      } else {
        fullURL = parsedUrl.pathname;
      }
      return encodeURIComponent(fullURL);
    }
    return null;
  }

  processUrl(originalUrl: string) : URL | null {
    try {
      return new URL(originalUrl, this.getWindow()?.location.origin);
    } catch (error) {
      return null;
    }
  }

  getWindow(): Window | null {
    return this.isBrowser() ? window : this._doc.defaultView;
  }

  isBrowser = () => isPlatformBrowser(this.platformId);

  nativeWindow() {
    if (isPlatformBrowser(this.platformId)) {
      return window;
    } else {
      return null;
    }
  }

}
