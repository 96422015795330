import { Observable } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
import { RedirectUrlService } from '../../services/redirectUrl-service/redirectUrl.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn} from '@angular/router';

@Injectable({
  providedIn: 'root'
})

class PermissionsService {

  constructor(
    private router: Router,
    private _sessionService: SessionService,
    private _redirectUrlService: RedirectUrlService) {}

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this._sessionService.getAccessToken()) {
      return true;
    } else {
      const redirectParam = this._redirectUrlService.getQueryParameterByName(state.url);
      if(redirectParam) {
        this.router.navigate(['/login'], { queryParams: { redirectURL: redirectParam } });
      } else {
        this.router.navigate(['/login']);
      }
      return false;
    }
  }

}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(PermissionsService).canActivate(next, state);
}
